import React, { Image } from "react";
import "./Quali.css";
import "./Impressum.css";
import img2019 from "./img/img13.jpg";
import img2021 from "./img/imgQuali2021.png";
import img08 from "./img/img8.png";

export default function Quali() {
  return (
    <div className="qualiContentContainer">
      <div className="qualiContentContainer">
        <div className="imgContainer" style={{ width: 300, height: 300 }}>
          <img
            src={img08}
            style={{ width: 300, height: 300 }}
            alt="Diabetiker Fußpflege"
          />
        </div>
        {/* <div className="imgContainer">
                <img style={{width:'100%', height:"100%"}} 
                src={img2019} alt="Logo" />
                </div> */}
        <div className="imgContainer" style={{ width: 420, height: 600 }}>
          <img
            style={{ width: "95%", height: "100%" }}
            src={img2021}
            alt="Diabetische Fußpflege 2021"
          />
        </div>
      </div>
    </div>
  );
}
