import React from 'react';
import './Impressum.css';



export default function Impressum() {
    return (
       <div>
<div className="impress-content">

<div className="kontakt impress-card-right">
                    Fußpflege Jeannette <br></br>
                    Jeannette Nakovits <br></br>
                    Mühlfeldg. 5, 1020 Wien <br></br>
                   <span> <a className="tel" href="tel:+4369910587104">0699 105 87 104</a>
                   <a className="email" href="mailto:office@fusspflege-jeannette.at">
                      office@fusspflege-jeannette.at
                    </a></span>
                </div>

</div>



         </div>
    )
}
