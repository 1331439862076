import React from "react";
import { MemoryRouter } from "react-router-dom";
import "./Home.css";
import jeanette from "../img/foto.jpg";
import ImageSlider from "ac-react-simple-image-slider";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Impressum from "./Impressum";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(name, price) {
  return { name, price };
}

const rows = [
  createData("Fußpflege", 28.0),
  createData("Handpflege", 18.0),
  createData("Paraffinpackung", 12.0),
  createData("Nagelspange mit Gel", 14.0),
  createData("Fußmassage", 12.0),
  createData("Lackieren", 3.0),
  createData("Hausbesuch", 40.0),
];
const imageData = [
  {
    src: "https://www.ni-it.at/menue/kunden/nakovits/Old/img/img2.jpg",
    title: "Image 1",
  },
  {
    src: "https://www.ni-it.at/menue/kunden/nakovits/Old/img/img5.jpg",

    title: "Image 2",
  },
  {
    src: "https://www.ni-it.at/menue/kunden/nakovits/Old/img/img3.jpg",
    title: "Image 3",
  },
  {
    src: "https://www.ni-it.at/menue/kunden/nakovits/Old/img/img6.jpg",
    title: "Image 4",
  },
  {
    src: "https://www.ni-it.at/menue/kunden/nakovits/Old/img/img1.jpg",

    title: "Image 5",
  },
  {
    src: "https://www.ni-it.at/menue/kunden/nakovits/Old/img/img11.jpg",

    title: "Image 5",
  },
  {
    src: "https://www.ni-it.at/menue/kunden/nakovits/Old/img/img10.jpg",

    title: "Image 5",
  },
  {
    src: "https://www.ni-it.at/menue/kunden/nakovits/Old/img/img4.jpg",

    title: "Image 5",
  },
  {
    src: "https://www.ni-it.at/menue/kunden/nakovits/Old/img/img9.jpg",

    title: "Image 5",
  },
  {
    src: "https://www.ni-it.at/menue/kunden/nakovits/Old/img/img12.jpg",

    title: "Image 5",
  },
];

export default function Home() {
  const classes = useStyles();

  return (
    <div className="home-card-wrapper">
      <div className="home-card-left">
        <img src={jeanette} alt="Jeanette" />
      </div>
      <div className="home-card-right">
        <p>
          <span>Herzlich&nbsp;willkommen!</span>
          Ich bin Mo bis Fr zwischen<br></br>
          &nbsp;08:00 - 16:00 für Sie da,<br></br>sowie nach Vereinbarung
        </p>
      </div>
      <div className="gallery-wrapper">
        <div className="gallery">
          <ImageSlider
            showDots={false}
            duration={10}
            height="90vh"
            width="100%"
            data={imageData}
          />
        </div>
      </div>
      <div className="pricelist-wrapper">
        <div className="pricelist-list-wrapper">
          <TableContainer>
            <Table
              className={classes.table}
              className={"table-wrapper"}
              aria-label="simple table"
            >
              <TableBody>
                {rows.map((row) => (
                  <TableRow>
                    <TableCell
                      style={{ borderBottom: "none" }}
                      width="140"
                      component="th"
                      scope="row"
                    >
                      {row.name}{" "}
                    </TableCell>
                    <TableCell
                      style={{ borderBottom: "none" }}
                      width="30"
                      align="right"
                    >
                      {row.price}.00 €
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>

      <div className="kontakt-wrapper">
        <div className="kontakt">
          Fußpflege Jeannette <br></br>
          Jeannette Nakovits <br></br>
          Mühlfeldg. 5, 1020 Wien <br></br>
          <span>
            {" "}
            <a className="tel" href="tel:+4369910587104">
              0699 105 87 104
            </a>
            <a className="email" href="mailto:office@fusspflege-jeannette.at">
              office@fusspflege-jeannette.at
            </a>
          </span>
        </div>
      </div>
    </div>
  );
}
