import React, {Image} from 'react'
import logo from '../img/logo.png';
import Navigation from './Navigation'
import './Header.css';
import Quali from './Quali'

export default function Header() {
    return (
      <div className="head">
          <div className="logo-wrapper">
         <img src={logo} alt="Logo" />
         </div>
         <Navigation />
    </div>

         
        
    )
}
