import React from 'react'

export default function Footer() {
    return (
      
        <div className="google-maps-wrapper">
            <div className="google-map-code-quali">
                <iframe 
                        src="https://maps.google.de/maps?hl=de&q=Fußpflege+Jeannette+%20%20Mühlfeldgasse+5++%20Wien&t=&z=9&ie=utf8&iwloc=b&output=embed " 
                        width="100%" 
                        height="300"
                        scrolling="no"  
                        frameborder="0" 
                        style={{border:0}} 
                        allowfullscreen="" 
                        aria-hidden="false" 
                        tabindex="0"
                        marginheight="0" 
                            marginwidth="0"
                        >
                </iframe>
            </div> 
        </div>
        
    )
}
