import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./Navigation.css";

import Quali from "./Quali";
import Home from "./Home";
import Impressum from "./Impressum";

export default function Header() {
  return (
    <Router>
      <div className="navBar">
        <nav>
          <ul>
            <li>
              <Link to="/impressum.php">Impressum</Link>
            </li>
            <li>
              <Link to="/qualifikationen.php">Qualifikationen</Link>
            </li>
            <li>
              <Link to="/">Startseite</Link>
            </li>
          </ul>
        </nav>
      </div>
      <Switch>
        <Route path="/impressum.php">
          <Impressum />
        </Route>
        <Route path="/qualifikationen.php">
          <Quali />
        </Route>
        <Route path="/">
          <Home />
        </Route>
        <Route path="/*">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}
